import React, {Fragment,useRef,useEffect} from 'react';
import Logo from '../../assets/images/Logo.png';
import { Link, animateScroll as scroll } from "react-scroll";
import gsap from 'gsap';

const Navbar = () => {

    const navRef = useRef(null);

    useEffect( () => {

        gsap.from(navRef.current,
            {
                duration:1,
                autoAlpha:0,
                ease:'none',
                delay:1
            })

    },[navRef])

    return (
        <Fragment>
            <nav ref={navRef} className="sticky-top nav">
                <div className="container">
                    <Link
                        activeClass="active"
                        to="home"
                        spy={true}
                        smooth={true}
                        offset={0}
                        duration={500}
                        className="logo"
                    >
                        <img alt="" src={Logo}/>
                        <span className="text-white-50">akogwu</span>emmanuel.com
                    </Link>

                    <div className="menu-icon">
                        <div className="hamburger"/>
                    </div>
                    <div className="menu">
                        <ul className="ml-md-auto">
                            <li>
                                <Link
                                    activeClass="active"
                                    to="about"
                                    spy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={500}
                                >About</Link>
                            </li>

                            <li>
                                <Link
                                    activeClass="active"
                                    to="portfolio"
                                    spy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={500}
                                >Work</Link>
                            </li>
                            <li>
                                <Link
                                    activeClass="active"
                                    to="contact"
                                    spy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={500}
                                >Contact</Link>
                            </li>

                        </ul>
                    </div>
                </div>
            </nav>
        </Fragment>
    );
}

export default Navbar;