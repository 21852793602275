import React, {Fragment,useEffect,useState} from 'react';
import Navbar from "./Navbar";
import About from "../About";
import Experience from "../Experience";
import Noteworthy from "../Noteworthy";
import Clients from "../Clients";

import Mouse from '../../assets/images/mouse.svg';
import Projects from "../Projects";
import Contact from "../Contact";
import axios from 'axios';
import api from "../../Helper/Config";
import { Link } from "react-scroll";
const Home = () => {

    const [projects,setProject] = useState([]);
    const [experiences,setExperiences] = useState([]);
    const [perPage,setPerPage] = useState(4);
    const [offset,setOffset] = useState(0)
    const [checkTotal,setCheckTotal] = useState(0);
    const [total,setTotal] = useState(0);

    useEffect( () => {
        axios.get(api.baseUrl+`projects?per_page=${perPage}&offset=${offset}`).then( res => {
            setProject(res.data);
            setTotal(res.headers['x-wp-total']);
        });
    },[offset]);

    const next = () => {
        setCheckTotal(checkTotal+offset);
        if (checkTotal >= total) return;
        setOffset(offset+perPage);
    }

    const previous=() =>{
        if(offset <= 0) return;
        setOffset(offset-perPage);
    }

    return (
        <Fragment>
            <header className="banner" id="home">
                <Navbar/>
                <div className="container">
                    <section className="hero-content">
                        <a className="email" href="mailto:contact@akogwuemmanuel.com">contact@akogwuemmanuel.com</a>
                        <div className="hero-description">
                            <h3 className="text-white-50">Hi, my name is</h3>
                            <h1 className="text-white">Akogwu <span>Emmanuel.</span></h1>
                            <h2 className="">I build Amazing Stuff for the Web.</h2>
                            <p>I'm a seasoned full-stack developer, based in Fairfield Iowa, USA, specializing in building exceptional,
                                high-quality websites and web applications.</p>
                            <Link
                                activeClass="active"
                                to="contact"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                                className="get-in-touch"
                            >
                                Get in touch
                            </Link>
                            <img alt="" className="mouse" src={Mouse}/>
                        </div>
                        <div className="social-icons">
                            <ul>
                                <li><a href=""><i className="fa fa-linkedin"/></a></li>
                                <li><a href=""><i className="fa fa-facebook"/> </a></li>
                                <li><a href=""><i className="fa fa-instagram"/> </a></li>
                                <li><a href=""><i className="fa fa-twitter"/> </a></li>
                                <li></li>
                            </ul>
                        </div>
                    </section>
                </div>
            </header>
            <About/>
            <Projects projects={projects} next={next} previous={previous}/>
            <Clients/>
            <Contact/>
        </Fragment>
    );
}

export default Home;